import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Link } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import React, { useEffect, useState } from "react";
import IProduct from "../../../models/Products/IProduct";
import ISerial from "../../../models/Products/ISerial";
import GroupedDropDown from "../../GroupedDropDown";
import IGroupedDropDownSuboption from "../../../models/IGroupedDropDownSuboption";
import { groupByFamilyAndByBundleName } from "../../../Utilities/productsHelper";
import { enterKey, handleBackdropClick } from "../../../utility";
import { CurrentProductSelectionContext } from "../../CurrentProductSelectionContext";
import BackTo from "../../BackTo";

interface ISubmitDialogProps {
  onCancel: () => void;
  onSubmit: (product: IProduct, serial: ISerial, changeToProduct: IProduct) => void;
  showDialog: boolean;
  product: IProduct;
  products: IProduct[];
  serial: ISerial;
  onHandleBackToEditSerial: () => void;
  isActionInProgress: boolean;
  migrateToProd: IProduct | undefined;
}
const MigrateDialog: React.FC<ISubmitDialogProps> = ({ showDialog, onCancel, onSubmit, product, products, serial, onHandleBackToEditSerial, isActionInProgress, migrateToProd }) => {
  const [migrateProductError, setMigrateProductError] = useState("");
  const [groupedOptions, setGroupedOptions] = useState<Record<string, IGroupedDropDownSuboption[]>[]>([]);
  const [selectedOptionId, setSelectedOptionId] = useState("--Select--");
  const [selectedProduct, setSelectedProduct] = useState<IProduct>();
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(true);

  useEffect(() => {
    setConfirmButtonDisabled(isActionInProgress || selectedOptionId === "--Select--");
  }, [isActionInProgress, selectedOptionId]);

  useEffect(() => {
    setSelectedOptionId("--Select--");
    setGroupedOptions(groupByFamilyAndByBundleName(products, product.type));
    setMigrateProductError("");
    if (migrateToProd) {
      setSelectedOptionId(migrateToProd.id);
      const selProd = products.filter((x: IProduct) => x.id === migrateToProd.id)[0];
      if (selProd) {
        setSelectedProduct(selProd);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products, migrateToProd]);

  const onProductSelected = (value: string) => {
    setMigrateProductError("");
    setSelectedOptionId(value);
    setSelectedProduct(products.filter((x: IProduct) => x.id === value)[0]);
  };

  const handleOnMigrate = () => {
    if (selectedOptionId === "--Select--") {
      setMigrateProductError("Select a product/service");
    } else if (selectedProduct) {
      onSubmit(product, serial, selectedProduct);
    }
  };

  const handleBackToEditSerial = (e: any) => {
    e.preventDefault();
    if (!isActionInProgress) {
      onHandleBackToEditSerial();
    }
  };

  return (
    <div>
      <Dialog onKeyUp={(event: any) => enterKey(event, handleOnMigrate)} disableEscapeKeyDown={isActionInProgress} data-testid="showMigrateSerialDialog" open={showDialog} onClose={(event: EventSource, reason: string) => handleBackdropClick(event, reason, onCancel)} classes={{ paper: "MigrateSerialDialogPaper" }}>
        <DialogTitle data-testid="showMigrateSerialDialogTItle" id="alert-dialog-title">
          CHANGE {product.subname}
        </DialogTitle>
        <DialogContent>
          <Grid container item direction="column" xs={12} spacing={2} style={{ overflowY: "auto" }} justifyContent={"center"}>
            <Grid item xs={12}>
              <BackTo
                text={`Edit ${product.subname}`}
                callback={(e: any) => {
                  handleBackToEditSerial(e);
                }}
                testId="goBackToEditSerialLink"
                disable={isActionInProgress}
              />
            </Grid>

            <Grid container direction="row" item xs={12}>
              <Grid container item xs={12} direction="column">
                <Grid item>
                  <Typography data-testid="migrateDialogProductChangeFromLabel" variant="body1" style={{ fontWeight: "bold" }}>
                    Change From:
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={6} direction="column">
                <Grid item>
                  <Typography data-testid="migrateDialogProductLabel" variant="button">
                    PRODUCT/SERVICE
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography data-testid="migrateDialogProductValue" variant="body2" style={{ fontWeight: "bold" }}>
                    {product.name}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={6} direction="column" style={{ alignContent: "right", flexDirection: "column", justifyContent: "flex-start", display: "flex" }}>
                <Grid item style={{ textAlign: "right" }}>
                  <Typography data-testid="migrateDialogSerialLabel" variant="button">
                    SERIAL
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography data-testid="migrateDialogSerialValue" variant="body2" style={{ fontWeight: "bold", textAlign: "right" }}>
                    {serial?.serial.length > 0 ? serial?.serial : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ paddingTop: 20 }}>
              <Typography data-testid="migrateDialogProductChangeToLabel" variant="body1" style={{ fontWeight: "bold" }}>
                Change To:
              </Typography>
              <div data-testid="migrateProductsDropdown">
                <CurrentProductSelectionContext.Provider value={product}>
                  <GroupedDropDown label={""} options={groupedOptions} selectedOptionId={selectedOptionId} error={migrateProductError} handleChange={onProductSelected} disabled={isActionInProgress} />
                </CurrentProductSelectionContext.Provider>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Link data-testid="productChangeRequestFormLink" variant="body2" target="_blank" href="https://status.barracudamsp.com/essentials-change/">
            PRODUCT CHANGE REQUEST FORM
          </Link>
          <div style={{ flex: "1 0 0" }} />
          <Button variant="text" data-testid="closeShowMigrateSerialsButton" size="large" onClick={onCancel} disabled={isActionInProgress}>
            CANCEL
          </Button>
          <Button data-testid="confirmShowMigrateSerialsButton" size="large" onClick={handleOnMigrate} disabled={confirmButtonDisabled} isLoading={isActionInProgress}>
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MigrateDialog;
