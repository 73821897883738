import Tooltip from "@cuda-networks/bds-core/dist/Tooltip";
import React from "react";
import { truncate } from "../utility";

interface ITruncatedText {
  value: string;
  bold: boolean;
  maxLength: number;
}

const TruncatedText: React.FC<ITruncatedText> = ({ value, bold, maxLength }) => {
  const nonTruncatedText = bold ? <b> {value}</b> : <div> {value}</div>;
  const truncatedText = bold ? <b> {truncate(value, maxLength)}</b> : <div> {truncate(value, maxLength)}</div>;

  return value.length > maxLength ? (
    <Tooltip PopperProps={{ style: { zIndex: 9000 } }} title={value}>
      {truncatedText}
    </Tooltip>
  ) : (
    nonTruncatedText
  );
};

export default TruncatedText;
