import React, { useEffect, useState } from "react";
import { Typography, Button, Divider, LinearProgress, CircularProgress } from "@cuda-networks/bds-core";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import MspType from "../../models/MspType";
import IAccount from "../../models/IAccount";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store/store";
import { deleteAccountAction, editAccountAction, getAccountAddressAction, adjustAccountsCurrentPageAfterDeleteAction, setSelectedAccountAction, navigateToAccountAction, setM365AuthAction, fetchM365AuthAction, revokeM365AuthAction, fetchM365DomainListAction, filterAccountsAction, isAccountInDisplayedItemsAction, getAccountInfoAction, getItemToNavigateToAfterDeleteAction, ensureContriesAndStatesAreLoadedAction } from "../../actions/accountActions";
import AddEditAccountDialog from "./AddEditAccount/AddEditAccountDialog";
import { setSelectedTabName, setSnackBarMessage } from "../../actions/generalActions";
import DetailsTabs from "../../models/DetailsTabs";
import ICountry from "../../models/ICountry";
import { getAccountDisplayType, getShowResourcesButton, setEditDialogTitle } from "../../Utilities/accountsHelper";
import AccountIcon from "./AccountIcon";
import { isNotFinance, isStringNullOrEmpty } from "../../utility";
import { Tooltip } from "@material-ui/core";
import SvgInfo from "@cuda-networks/bds-core/dist/Icons/Feedback/Info";
import { getAccountUsageInfoIconTooltip } from "../../businessLogic/components/Accounts/AccountUsageInfoIcon";
import AddressesCardPanel from "./AddressesCardPanel";
import IAccountAddress from "../../models/IAccountAddress";
import M365AuthLinkDialog from "./M365AuthLinkDialog";
import IAccountM365DomainList from "../../models/IAccountM365DomainList";
import PartnerResourcesButton from "./PartnerResourcesButton";
import M365LinkIcon from "./M356LinkIcon";
import ActionMessageType from "../../models/ActionMessageType";
import TabTitle from "../TabTitle";
import { ActionMessages, ActionTypes } from "../../actions/ActionTypes";
import DeleteAccountDialog from "./DeleteAccount/DeleteAccountDialog";
import { IDeleteAccountSerial } from "./DeleteAccount/DeleteAccountSerialsTable";
import { setTablePropsForDeleteAccountSerial } from "../../actions/productActions";
import { useNavigate } from "react-router-dom";
import useDocumentTitle, { PageTitles } from "../../Utilities/useDocumentTitle";
import { filtersAreActive } from "./FilterAccounts/UrlFilterParams";
import { UrlFilterExtractor } from "./FilterAccounts/UrlFilterExtractor";

const AccountDetailsTab = () => {
  const dispatch = useDispatch();
  const mspAccountLoggedIn = useSelector((state: IAppState) => state.generalState.mspAccountLoggedIn);
  const loadingAddress = useSelector((state: IAppState) => state.accountState.loadingAddress);
  const selectedAccount = useSelector((state: IAppState) => state.accountState.selectedAccount);
  const addressToDisplay = useSelector((state: IAppState) => state.accountState.addressToDisplay);
  const accountsOrders = useSelector((state: IAppState) => state.productState.accountsOrders);
  const filters = new UrlFilterExtractor(window.location.search, accountsOrders).extractFilters();
  const viewSearchResults = filtersAreActive();
  const loadingAccountInfo = useSelector((state: IAppState) => state.accountState.loadingAccountInfo);
  const loadingEchoUserName = useSelector((state: IAppState) => state.accountState.loadingEchoUserName);
  const echoUserName = useSelector((state: IAppState) => state.accountState.echoUserName);
  const notes = useSelector((state: IAppState) => state.accountState.notes);
  const deleteAccountSerialTableState = useSelector((state: IAppState) => state.productState.deleteAccountSerialTableState);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [showEditAccount, setShowEditAccount] = useState(false);
  const [showM365AuthLinkDialog, setShowM365AuthLinkDialog] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [loadedCountries, setLoadedCountries] = useState<ICountry[]>([]);
  const countries = useSelector((state: IAppState) => state.accountState.countries);
  const [dialogStep, setDialogStep] = useState(0);
  const [addressCardHeight, setAddressCardHeight] = useState(0);
  const [m365Unlinking, setM365Unlinking] = useState(false);
  const [m365AuthErrMsg, setM365AuthErrMsg] = useState("");
  const [m365AuthInProgress, setM365AuthInProgress] = useState(false);
  const [m365AuthFail, setM365AuthFail] = useState(false);
  const [m365DomainList, setM365DomainList] = useState<IAccountM365DomainList>();
  const [m365FetchDomainListErrMsg, setM365FetchDomainListErrMsg] = useState("");
  const [m365FetchDomainListFail, setM365FetchDomainListFail] = useState(false);
  const [m365FetchDomainListInProgress, setM365FetchDomainListInProgress] = useState(false);
  const [m365FetchDomainListSucceed, setM365FetchDomainListSucceed] = useState(false);
  const [showResourcesButton, setShowResourcesButton] = useState(false);

  const [showEchoUserName, setShowEchoUserName] = useState(false);
  const [showCustomField, setShowCustomField] = useState(false);
  const [notesValue, setNotesValue] = useState("--");

  const navigate = useNavigate();

  useDocumentTitle(selectedAccount, PageTitles.Details);

  useEffect(() => {
    const handlePopState = (event: any) => {
      setShowEditAccount(false);
      setShowDeleteAccount(false);
      setShowM365AuthLinkDialog(false);
    };
    window.addEventListener("popstate", handlePopState);
    return () => window.removeEventListener("popstate", handlePopState);
  }, []);

  useEffect(() => {
    setShowResourcesButton(getShowResourcesButton(mspAccountLoggedIn, selectedAccount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mspAccountLoggedIn, selectedAccount]);

  useEffect(() => {
    setLoadedCountries(countries);
  }, [countries]);

  const loadM365Status = (item: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365AuthAction(item));
      resolve(result);
    });

  useEffect(() => {
    if (selectedAccount !== undefined && selectedAccount?.m365AuthLinked === undefined) {
      setM365AuthInProgress(true);
      loadM365Status(selectedAccount).then(() => {
        setM365AuthInProgress(false);
      });
    } else {
      setM365FetchDomainListInProgress(true);
      if (selectedAccount?.m365AuthLinked && selectedAccount?.m365AuthLinked === 1) {
        setInitialM365AuthLinkDialogStatus();
        new Promise<any>((resolve, reject) => {
          const result = dispatch(fetchM365DomainListAction(selectedAccount!, 0, 5));
          resolve(result);
        })
          .then((success: any) => {
            setM365DomainList(success);
            setM365FetchDomainListSucceed(true);
          })
          .catch(async (error: Error) => {
            setM365FetchDomainListFail(true);
            setM365FetchDomainListErrMsg(error.message);
          });
      }
      setM365FetchDomainListInProgress(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount]);

  useEffect(() => {
    if (notes && !isStringNullOrEmpty(notes)) {
      setNotesValue(notes);
    } else {
      setNotesValue("--");
    }
  }, [notes]);

  const loadCountriesAndStates = (addressToDisplay: IAccountAddress) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(ensureContriesAndStatesAreLoadedAction(addressToDisplay));
      resolve(result);
    });

  const handleOpenEditDialog = (isBilling: boolean) => {
    setDialogStep(isBilling ? 1 : 0);
    setShowEditAccount(!showEditAccount);
    if (selectedAccount && addressToDisplay) {
      setLoadingEdit(true);
      loadCountriesAndStates(addressToDisplay).then(result => {
        if (result) {
          setLoadingEdit(false);
        }
      });
    }
  };

  const handleOnEditClicked = (entity: IAccount, notes?: string) => {
    setIsActionInProgress(true);
    editAccount(entity, notes);
  };

  const handleOnCancel = () => {
    setShowEditAccount(!showEditAccount);
  };

  const handleOnDelete = () => {
    setShowDeleteAccount(true);
  };

  const handleOnCancelDeleteDialog = () => {
    setShowDeleteAccount(false);
    dispatch(setTablePropsForDeleteAccountSerial({ ...deleteAccountSerialTableState, skip: 0 }));
  };

  const deleteAccount = (account: IAccount, deleteAccountSerials: IDeleteAccountSerial[] | undefined) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(deleteAccountAction(account, deleteAccountSerials));
      resolve(success);
    });

  const getItemToNavigateToAfterDelete = (item: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(getItemToNavigateToAfterDeleteAction(item));
      resolve(success);
    });

  const handleOnSubmitDeleteDialog = (deleteAccountSerials: IDeleteAccountSerial[] | undefined) => {
    if (selectedAccount) {
      setIsActionInProgress(true);
      deleteAccount(selectedAccount, deleteAccountSerials).then(success => {
        if (success) {
          dispatch(setTablePropsForDeleteAccountSerial({ ...deleteAccountSerialTableState, skip: 0 }));
          dispatch(setSnackBarMessage({ message: `${getAccountDisplayType(selectedAccount.type)} successfully deleted.`, type: ActionMessageType.Success }));
          dispatch(adjustAccountsCurrentPageAfterDeleteAction());
          dispatch(setSelectedAccountAction(undefined));
          if (!viewSearchResults) {
            getItemToNavigateToAfterDelete(selectedAccount).then(success1 => {
              if (success1) {
                const targetPath = `/accounts/${success1}/details`; //todo leave the filters in the url
                navigate(targetPath + window.location.search);
                setIsActionInProgress(false);
              } else {
                navigate("/" + window.location.search); //todo leave the filters in the url
                setIsActionInProgress(false);
              }
            });
          } else {
            navigate("/" + window.location.search); //todo leave the filters in the url
            setIsActionInProgress(false);
          }
        }
      });
    }
  };

  const updateAccount = (accountToUpdate: IAccount, notes?: string) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(editAccountAction(accountToUpdate, notes));
      resolve(success);
    });

  const isAccountInDisplayedItems = (accountToUpdate: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const success = dispatch(isAccountInDisplayedItemsAction(accountToUpdate));
      resolve(success);
    });

  const editAccount = (entity: IAccount, notes?: string) => {
    updateAccount(entity, notes).then(success => {
      setIsActionInProgress(false);
      setShowEditAccount(!success);
      if (success) {
        isAccountInDisplayedItems(entity).then(found => {
          if (found === true) {
            dispatch(navigateToAccountAction(entity.id));
            dispatch(getAccountAddressAction(entity));
            dispatch(getAccountInfoAction(entity));
            dispatch(setSelectedTabName(DetailsTabs.AccountsDetails));
            dispatch(setSelectedAccountAction(entity));
            dispatch(fetchM365AuthAction(entity));
            dispatch(setSnackBarMessage({ message: `${getAccountDisplayType(entity.type)} successfully updated.`, type: ActionMessageType.Success }));
          } else {
            dispatch(adjustAccountsCurrentPageAfterDeleteAction());
            dispatch(setSelectedAccountAction(undefined));

            navigate("/filters" + window.location.search);
          }
        });
      }
    });
  };

  const setInitialM365AuthLinkDialogStatus = () => {
    setM365FetchDomainListSucceed(false);
    setM365FetchDomainListFail(false);
    setM365FetchDomainListErrMsg("");
    setM365AuthErrMsg("");
    setM365AuthInProgress(false);
    setM365AuthFail(false);
  };

  const handleOnCancelM365AuthLinkDialog = () => {
    setShowM365AuthLinkDialog(false);
  };

  const m365InfoIcon = () => {
    return (
      <Tooltip title={getAccountUsageInfoIconTooltip}>
        <span data-testid="m365InfoIcon">
          <SvgInfo color={"secondary"}></SvgInfo>
        </span>
      </Tooltip>
    );
  };

  const fetchM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365AuthAction(account));
      resolve(result);
    });

  const connectM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(setM365AuthAction(account));
      resolve(result);
    });

  const revokeM365Auth = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(revokeM365AuthAction(account));
      resolve(result);
    });

  const fetchM365DomainList = (account: IAccount) =>
    new Promise<any>((resolve, reject) => {
      const result = dispatch(fetchM365DomainListAction(account, 0, 5));
      resolve(result);
    });

  async function retry(fn: any, retriesLeft = 3, interval = 10000) {
    return await fn
      .then((success: any) => {
        setM365FetchDomainListInProgress(false);
        setM365DomainList(success);
        setM365FetchDomainListSucceed(true);
      })
      .catch(async (error: Error) => {
        if (retriesLeft) {
          await new Promise(r => setTimeout(r, interval));
          return retry(fetchM365DomainList(selectedAccount!), retriesLeft - 1, interval);
        } else {
          setM365FetchDomainListInProgress(false);
          setM365FetchDomainListFail(true);
          setM365FetchDomainListErrMsg(error.message);
        }
      });
  }

  const handleOnBtnM365DomainList = (account?: IAccount) => {
    setShowM365AuthLinkDialog(true);
  };

  const fetchDomainListsAfterAuth = (account?: IAccount) => {
    setInitialM365AuthLinkDialogStatus();
    setM365FetchDomainListInProgress(true);
    if (account) {
      retry(fetchM365DomainList(account), 3);
    }
  };

  const handleOnBtnM365Link = (account?: IAccount) => {
    if (account == null) {
      return;
    }

    setInitialM365AuthLinkDialogStatus();
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      setM365Unlinking(true);
      revokeM365Auth(account).then(success => {
        if (success.status === "success") {
          dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.UnlinkAccountFromM365].successMessage, type: ActionMessageType.Success }));
          fetchM365Auth(account).finally(() => {
            setM365Unlinking(false);
          });
        } else {
          setM365Unlinking(false);
        }
        if (filters?.onlyM365Linked || filters?.onlyM365Unlinked) {
          dispatch(filterAccountsAction(filters));
          navigate("/filters" + window.location.search);
        }
      });
    } else {
      setShowM365AuthLinkDialog(true);
      setM365AuthInProgress(true);
      connectM365Auth(account).then(success => {
        if (success && success.isSuccessful && success.authorizeUrl) {
          let popupWindow = window.open(success.authorizeUrl, "", "");
          if (popupWindow !== null) {
            const intervalId = setInterval(function () {
              if (popupWindow?.closed) {
                clearInterval(intervalId);
                handleOnCancelM365AuthLinkDialog();
                return;
              }
              let href = "";
              try {
                if (popupWindow?.location.href) {
                  href = popupWindow?.location.href;
                }
              } catch (e) {}

              if (href === "" || href === "about:blank") {
                return;
              }
              const url = new URL(href);
              const curUrl = new URL(window.location.href);
              if (url.hostname === curUrl.hostname) {
                popupWindow?.close();
                if (url.searchParams.get("error") != null && url.searchParams.get("error_description") != null) {
                  let desc = url.searchParams.get("error_description");
                  if (desc !== null) {
                    setM365AuthInProgress(false);
                    setM365AuthErrMsg(desc);
                    setM365AuthFail(true);
                  }
                } else {
                  setM365AuthInProgress(false);
                  fetchDomainListsAfterAuth(account);
                  dispatch(setSnackBarMessage({ message: ActionMessages[ActionTypes.LinkAccountToM365].successMessage, type: ActionMessageType.Success }));
                }
                setM365Unlinking(true);
                fetchM365Auth(account).finally(() => {
                  setM365Unlinking(false);
                });
              }
              clearInterval(intervalId);
            }, 50);
          }
          if (filters?.onlyM365Linked || filters?.onlyM365Unlinked) {
            dispatch(filterAccountsAction(filters));
            navigate("/filters" + window.location.search);
          }
        }
      });
    }
  };

  const m365LinkButtonText = (account?: IAccount) => {
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      return "UNLINK M365 ACCOUNT";
    }
    return "LINK M365 ACCOUNT";
  };

  const m365DomainListButton = (account?: IAccount) => {
    if (account?.m365AuthLinked && account?.m365AuthLinked === 1) {
      return (
        <div style={{ display: "inline", marginLeft: "15px" }}>
          <Button data-testid="m365DomainBtn" variant={"contained"} color={"secondary"} size={"medium"} onClick={() => handleOnBtnM365DomainList(account)}>
            DOMAIN LIST
          </Button>
        </div>
      );
    }
  };

  const m365LinkButton = (account?: IAccount) => {
    if (mspAccountLoggedIn.type !== MspType.Customer && isNotFinance(mspAccountLoggedIn) && account) {
      if (account.m365AuthLinked === undefined || m365FetchDomainListInProgress || account.closestParentId === 0 || account.closestParentId === undefined) {
        return <CircularProgress />;
      }

      return (
        <Grid>
          <Button data-testid="m365Btn" isLoading={m365Unlinking} variant={"contained"} color={"secondary"} size={"medium"} onClick={() => handleOnBtnM365Link(account)}>
            {m365LinkButtonText(account)}
          </Button>
          {m365DomainListButton(account)}
        </Grid>
      );
    }
  };

  const m365LinkHeader = (account?: IAccount) => {
    return (
      <div style={{ display: "flex" }}>
        <Typography data-testid="m365Lbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
          M365 Account
        </Typography>
        <div className={"headerM365LinkUserIcon"}>{m365InfoIcon()}</div>
        <div className={"headerM365LinkUserIcon"}>
          <M365LinkIcon account={account} />
        </div>
      </div>
    );
  };

  interface IM365ButtonProps {
    loggedIn: IAccount;
    selected: IAccount;
  }

  const M365LinkButton: React.FC<IM365ButtonProps> = ({ loggedIn, selected }) => {
    return (
      <div>
        {mspAccountLoggedIn.type !== MspType.Customer && selected && m365LinkHeader(selected)}
        <Grid container item direction="row">
          {m365LinkButton(selected)}
        </Grid>
      </div>
    );
  };

  useEffect(() => {
    if (selectedAccount?.type.includes(MspType.Customer)) {
      if (loadingAccountInfo || loadingAddress) {
        setShowCustomField(false);
      } else {
        setShowCustomField(true);
      }
    } else {
      setShowCustomField(false);
    }
  }, [selectedAccount, loadingAccountInfo, loadingAddress]);

  useEffect(() => {
    if (selectedAccount?.type === MspType.BillingAggregator || loadingEchoUserName || loadingAddress) {
      setShowEchoUserName(false);
    } else {
      setShowEchoUserName(true);
    }
  }, [selectedAccount, loadingEchoUserName, loadingAddress]);

  const handleSetHeight = (value: number) => {
    setAddressCardHeight(value);
  };

  const showDeleteButton = () => {
    let button = null;
    if (mspAccountLoggedIn.userAdminFlag) {
      if (mspAccountLoggedIn.id !== selectedAccount?.id) {
        button = (
          <Button data-testid="deleteAccountBtn" isLoading={loadingAccountInfo} variant="contained" color="secondary" type={"submit"} disabled={isActionInProgress || loadingAccountInfo} onClick={handleOnDelete}>
            DELETE
          </Button>
        );
      }
    }
    return button;
  };

  return (
    <Grid container item xs={12} className={"AccountDetailsTab"}>
      <Grid item xs={12} style={{ alignContent: "center", textAlign: "left" }}>
        <Grid item container spacing={3} direction="column">
          <Grid item container>
            <Grid container item xs={10}>
              <TabTitle tabType={DetailsTabs.AccountsDetails} item={selectedAccount} />
            </Grid>
            <Grid item xs={2} className="centerVertically">
              <Grid container item direction="row" style={{ justifyContent: "end" }}>
                {showDeleteButton()}
              </Grid>
            </Grid>
          </Grid>

          <Grid container item xs={12} direction="row">
            <Grid item xs={3}>
              <Typography data-testid="idLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                ID
              </Typography>
              <Typography data-testid="idTxt" variant="h6" gutterBottom>
                {selectedAccount?.id}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography data-testid="typeLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                Type
              </Typography>
              <Grid container item direction="row">
                <div style={{ position: "relative", top: "4px", right: "3px" }}>
                  <AccountIcon entityType={selectedAccount?.type ? selectedAccount?.type : ""}></AccountIcon>
                </div>
                <Typography data-testid="typeTxt" variant="h6" gutterBottom>
                  {getAccountDisplayType(selectedAccount?.type)}
                </Typography>
              </Grid>
            </Grid>
            {selectedAccount?.type === MspType.Customer && (
              <Grid container item xs={6} direction="column">
                <M365LinkButton loggedIn={mspAccountLoggedIn} selected={selectedAccount} />
              </Grid>
            )}
            {showResourcesButton && (
              <Grid item xs style={{ marginRight: "15px", flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                <PartnerResourcesButton />
              </Grid>
            )}
          </Grid>

          <Grid container item xs={12} direction="row">
            {showEchoUserName && (
              <Grid item xs={3}>
                <Typography data-testid="EchoUserNameLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Echo Username
                </Typography>
                <Typography data-testid="EchoUserNameTxt" variant="h6" gutterBottom>
                  {echoUserName}
                </Typography>
              </Grid>
            )}
            {showCustomField && (
              <Grid item xs={6}>
                <Typography data-testid="NotesLbl" variant="subtitle2" style={{ color: "#8a8a8a" }}>
                  Notes
                </Typography>
                <Typography data-testid="NotesTxt" variant="h6" gutterBottom>
                  {notesValue}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Divider />
        {loadingAddress && <LinearProgress data-testid="loadingAddress" />}
      </Grid>

      {!loadingAddress && addressToDisplay && <AddressesCardPanel mspAccountLoggedIn={mspAccountLoggedIn} selectedAccount={selectedAccount} addressToDisplay={addressToDisplay} handleOpenEditDialog={(isBilling: boolean) => handleOpenEditDialog(isBilling)} handleSetHeight={(value: number) => handleSetHeight(value)} loadingEdit={loadingEdit} addressCardHeight={addressCardHeight} countries={loadedCountries} />}

      {showEditAccount && selectedAccount && <AddEditAccountDialog dialogStep={dialogStep} showDialog={showEditAccount} onCancel={handleOnCancel} selectedItem={selectedAccount} addressToDisplay={addressToDisplay} mspType={selectedAccount.type as MspType} /*TODO*/ isActionInProgress={isActionInProgress} isEdit={true} account={selectedAccount} onSubmit={(closestParentId: number, entity: IAccount, linkToM365?: boolean, notes?: string) => handleOnEditClicked(entity, notes)} title={setEditDialogTitle(selectedAccount)} loadingAddEditDialog={loadingEdit} />}
      {showDeleteAccount && selectedAccount && <DeleteAccountDialog account={selectedAccount} onCancel={handleOnCancelDeleteDialog} onSubmit={handleOnSubmitDeleteDialog} actionInProgress={isActionInProgress} />}
      {showM365AuthLinkDialog && <M365AuthLinkDialog authErrMsg={m365AuthErrMsg} showDialog={showM365AuthLinkDialog} onCancel={handleOnCancelM365AuthLinkDialog} authInProgress={m365AuthInProgress} authFail={m365AuthFail} authDomainList={m365DomainList!} fetchDomainListSucceed={m365FetchDomainListSucceed} fetchDomainListFail={m365FetchDomainListFail} fetchDomainListErrMsg={m365FetchDomainListErrMsg} fetchDomainListInProgress={m365FetchDomainListInProgress} account={selectedAccount} />}
    </Grid>
  );
};
export default AccountDetailsTab;
