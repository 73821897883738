import { CancelToken, CancelTokenSource } from "axios";
import produce from "immer";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import IAccount from "../models/IAccount";
import IService from "../models/IService";
import MspType from "../models/MspType";
import IAccountProducts from "../models/Products/IAccountProducts";
import IProduct from "../models/Products/IProduct";
import IProductFamily from "../models/Products/IProductFamily";
import ISerial from "../models/Products/ISerial";
import IBundleSku from "../models/Products/IBundleSku";
import ProductFamily from "../models/Products/ProductFamily";
import mspService from "../service/mspService";
import { IAppState } from "../store/store";
import { State, process } from "@progress/kendo-data-query";
import { addProductForAccount, getStatusFromResponse, removeProductForAccount, overwriteSavedProductFamilyForAccount, exportCsvPayloadAccountsIds, overwriteOrdersForAccount, filterOrdersResult, productIsBBS } from "../Utilities/productsHelper";
import { getSubpartnerProductsSummary, ISubpartnersWithProducts } from "../models/Products/SubpartnersWithProducts";
import { handleError } from "./actionsErrorHandler";
import SerialStatus from "../models/Products/SerialStatus";
import { getAccountProductFromAccountProducts, getSerialsWithAccountId, updateBBSProductForParentAccountForAfterActivateDeactivate, updateBBSProductForParentAccountForAfterAssignUnassign, updateBBSProductsInStateAfterDeactivateSerial, updateESSCSProductForParentAccountForAfterCancelSerial, updateSerialStatusForParentProduct } from "../businessLogic/products";
import { TokenStorage } from "../TokenStorage";
import { isTokenExpiredError } from "../utility";
import { cancelGeneralActionTokenAndCreateNew, cancelLoadAllSerialsActionTokenAndCreateNew, cancelLoadCurrencyActionTokenAndCreateNew, cancelLoadParentProductsActionTokenAndCreateNew, cancelLoadSupartnersWithAssignedProductsActionToken, cancelLoadSupartnersWithAssignedProductsActionTokenAndCreateNew } from "./cancelAction";
import IAccountOrders from "../models/Products/IAccountOrders";
import { ActionTypes } from "./ActionTypes";
import { LocalStoragePreferences, localStorageService } from "../service/localStorageService";
import IOrder from "../models/Products/IOrder";
import { IDeleteAccountSerial } from "../components/Accounts/DeleteAccount/DeleteAccountSerialsTable";
import { computeSerialsForAccountToDelete } from "../businessLogic/components/Accounts/deleteAccounts";

export enum ProductActionTypes {
  GET_PRODUCTS = "GET_PRODUCTS",
  SET_EXPANDED_STATUS = "SET_EXPANDED_STATUS",
  GET_SERIALS_TO_DISPLAY = "GET_SERIALS_TO_DISPLAY",
  ACTIVATE_PRODUCTS = "ACTIVATE_PRODUCTS",
  ASSIGN_PRODUCTS = "ASSIGN_PRODUCTS",
  UNASSIGN_PRODUCTS = "UNASSIGN_PRODUCTS",
  SET_ACTIVATE_ASSIGN_ERROR = "SET_ACTIVATE_ASSIGN_ERROR",
  SET_SELECTED_PRODUCT = "SET_SELECTED_PRODUCT",
  SET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS_CANCEL_TOKEN = "SET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS_CANCEL_TOKEN",
  GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS = "GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS",
  GET_PRODUCTS_FOR_SUBPARTNERS = "GET_PRODUCTS_FOR_SUBPARTNERS",
  SET_ACCOUNT_PRODUCTS = "SET_ACCOUNT_PRODUCTS",
  GET_PARENT_MSP_PRODUCTS = "GET_PARENT_MSP_PRODUCTS",
  SET_PARENT_PRODUCTS_CANCEL_TOKEN = "SET_PARENT_PRODUCTS_CANCEL_TOKEN",
  GET_HAS_SUBPARTNERS_WITH_PRODUCTS = "GET_HAS_SUBPARTNERS_WITH_PRODUCTS",
  UPDATE_SKU_SERVICES_REPO = "UPDATE_SKU_SERVICES_REPO",
  SET_TABLE_PROPS_FOR_BBS = "SET_TABLE_PROPS_FOR_BBS",
  SET_TABLE_PROPS_FOR_ESS = "SET_TABLE_PROPS_FOR_ESS",
  SET_TABLE_PROPS_FOR_CS = "SET_TABLE_PROPS_FOR_CS",
  SET_TABLE_PROPS_FOR_SE = "SET_TABLE_PROPS_FOR_SE",
  DEACTIVATE_PRODUCTS = "DEACTIVATE_PRODUCTS",
  RETRY_ACTIVATION_PRODUCTS = "RETRY_ACTIVATION_PRODUCTS",
  CHANGE_SERIAL = "CHANGE_SERIAL",
  EXPORT_BILLING_USERS = "EXPORT_BILLING_USERS",
  FETCH_CHANGE_SERIAL_OPTIONS = "FETCH_CHANGE_SERIAL_OPTIONS",
  SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID = "SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID",
  SET_LOADING_PRODUCTS_CANCELED = "SET_LOADING_PRODUCTS_CANCELED",
  SET_LOADING_PARENT_PRODUCTS_CANCELED = "SET_LOADING_PARENT_PRODUCTS_CANCELED",
  CLEAR_PRODUCTS_FROM_SUBPARTNERS_WITH_PRODUCTS = "CLEAR_PRODUCTS_FROM_SUBPARTNERS_WITH_PRODUCTS",
  SELECTED_PARTNER_HAS_ACTIVE_ORDERLINES = "SELECTED_PARTNER_HAS_ACTIVE_ORDERLINES",
  GET_CURRENCY = "GET_CURRENCY",
  SET_CANCEL_LOAD_CURRENCY_TOKEN = "SET_CANCEL_LOAD_CURRENCY_TOKEN",
  EXPORT_CSV_REPORT = "EXPORT_CSV_REPORT",
  GET_ORDERS = "GET_ORDERS",
  SET_ACCOUNT_ORDERS = "SET_ACCOUNT_ORDERS",
  SET_TABLE_PROPS_FOR_SERIAL = "SET_TABLE_PROPS_FOR_SERIAL",
  GET_DELETE_ACCOUNT_SERIALS = "GET_DELETE_ACCOUNT_SERIALS",
  SET_CANCEL_GET_ALL_SERIALS_TOKEN = "SET_CANCEL_GET_ALL_SERIALS_TOKEN",
  UPDATE_PRODUCT_ID_REPO = "UPDATE_PRODUCT_ID_REPO",
  SET_TABLE_PROPS_FOR_DELETE_ACCOUNTS_SERIAL = "SET_TABLE_PROPS_FOR_DELETE_ACCOUNTS_SERIAL",
}

export interface IGetProductsAction {
  type: ProductActionTypes.GET_PRODUCTS;
  productsToDisplay: IProductFamily[];
  loadingProducts: boolean;
}

export interface ISetExpandedStatus {
  type: ProductActionTypes.SET_EXPANDED_STATUS;
  expandedStatus: Record<ProductFamily, boolean>;
}

export interface IGetProductSerialsToDisplayAction {
  type: ProductActionTypes.GET_SERIALS_TO_DISPLAY;
  serialsToDisplay: ISerial[];
}

export interface IActivateProductsAction {
  type: ProductActionTypes.ACTIVATE_PRODUCTS;
}

export interface IRetryActivationProductsAction {
  type: ProductActionTypes.RETRY_ACTIVATION_PRODUCTS;
}

export interface IDeactivateProductsAction {
  type: ProductActionTypes.DEACTIVATE_PRODUCTS;
}

export interface IAssignProductsAction {
  type: ProductActionTypes.ASSIGN_PRODUCTS;
}

export interface IUnassignProductsAction {
  type: ProductActionTypes.UNASSIGN_PRODUCTS;
}

export interface ISetActivateAssignErrorAction {
  type: ProductActionTypes.SET_ACTIVATE_ASSIGN_ERROR;
}

export interface ISetSelectedProductAction {
  type: ProductActionTypes.SET_SELECTED_PRODUCT;
  selectedProduct: IProduct;
}

export interface IGetSubpartnersWithProductsAssignedAction {
  type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS;
  subpartnersWithProducts: ISubpartnersWithProducts[];
  loadingSubpartnersWithProducts: boolean;
}

export interface IGetSubpartnersWithProductsAssignedCancelTokenAction {
  type: ProductActionTypes.SET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS_CANCEL_TOKEN;
  loadSubpartnersWithAssignedProductsCancellationTokenSource: CancelTokenSource | undefined;
}

export interface ISetAccountProducts {
  type: ProductActionTypes.SET_ACCOUNT_PRODUCTS;
  accountsProducts: IAccountProducts[];
}

export interface IGetParentMspProductsAction {
  type: ProductActionTypes.GET_PARENT_MSP_PRODUCTS;
  parentProductsFamilies: IAccountProducts;
  loadingParentMspProducts: boolean;
}

export interface ISetParentProductsCancelTokenAction {
  type: ProductActionTypes.SET_PARENT_PRODUCTS_CANCEL_TOKEN;
  loadParentProductsCancellationTokenSource: CancelTokenSource;
}

export interface IHasSubpartnersWithProducts {
  type: ProductActionTypes.GET_HAS_SUBPARTNERS_WITH_PRODUCTS;
  hasSubpartnersWithProducts: boolean;
  loadingHasSubpartnersWithProducts: boolean;
  subpartnersWithProducts: ISubpartnersWithProducts[];
}

export interface IUpdateSkuServicesRepo {
  type: ProductActionTypes.UPDATE_SKU_SERVICES_REPO;
  skuServicesRepo: IBundleSku[];
}

export interface ISetTablePropsForBBS {
  type: ProductActionTypes.SET_TABLE_PROPS_FOR_BBS;
  bbsTableState: State;
}

export interface ISetTablePropsForESS {
  type: ProductActionTypes.SET_TABLE_PROPS_FOR_ESS;
  essTableState: State;
}

export interface ISetTablePropsForSE {
  type: ProductActionTypes.SET_TABLE_PROPS_FOR_SE;
  seTableState: State;
}

export interface ISetTablePropsForCS {
  type: ProductActionTypes.SET_TABLE_PROPS_FOR_CS;
  csTableState: State;
}

export interface IChangeSerial {
  type: ProductActionTypes.CHANGE_SERIAL;
}

export interface IExportBillingUsers {
  type: ProductActionTypes.EXPORT_BILLING_USERS;
}

export interface IFetchChangeSerialOptions {
  type: ProductActionTypes.FETCH_CHANGE_SERIAL_OPTIONS;
}

export interface ISetLoadingProductsCanceled {
  type: ProductActionTypes.SET_LOADING_PRODUCTS_CANCELED;
  loadingProductsCanceledForAccountId: number;
}

export interface ISetLoadingProductsForAccountId {
  type: ProductActionTypes.SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID;
  loadingProductsForAccountId: number;
}

export interface ISetLoadingParentProductsCanceled {
  type: ProductActionTypes.SET_LOADING_PARENT_PRODUCTS_CANCELED;
  loadingParentProductsCanceled: boolean;
}

export interface IGetProductsForSubpartnersAction {
  type: ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS;
  loadingProductsForSubpartners: boolean;
}

export interface IClearSubpartnersWithProductsAction {
  type: ProductActionTypes.CLEAR_PRODUCTS_FROM_SUBPARTNERS_WITH_PRODUCTS;
  subpartnersWithProducts: ISubpartnersWithProducts[];
}

export interface IGetCurrency {
  type: ProductActionTypes.GET_CURRENCY;
  currency: string;
  loadingCurrency: boolean;
}

export interface ISetCancelLoadCurrencyToken {
  type: ProductActionTypes.SET_CANCEL_LOAD_CURRENCY_TOKEN;
  currencyCancellationTokenSource: CancelTokenSource;
}

export interface IExportCsvReport {
  type: ProductActionTypes.EXPORT_CSV_REPORT;
}

export interface IGetOrders {
  type: ProductActionTypes.GET_ORDERS;
  loadingOrdersForAccountId: number;
}

export interface ISetAccountOrders {
  type: ProductActionTypes.SET_ACCOUNT_ORDERS;
  accountsOrders: IAccountOrders[];
}

export interface ISetTablePropsForSerial {
  type: ProductActionTypes.SET_TABLE_PROPS_FOR_SERIAL;
  serialTableState: State;
}

export interface IGetDeleteAccountSerials {
  type: ProductActionTypes.GET_DELETE_ACCOUNT_SERIALS;
  deleteAccountSerialsToDisplay: IDeleteAccountSerial[] | undefined;
  allDeleteAccountSerials: IDeleteAccountSerial[] | undefined;
  loadingDeleteAccountSerials: boolean | undefined;
}

export interface ISetCancelGetAllSerialsToken {
  type: ProductActionTypes.SET_CANCEL_GET_ALL_SERIALS_TOKEN;
  getAllSerialsCancellationTokenSource: CancelTokenSource;
}

export interface IUpdateProductIdRepo {
  type: ProductActionTypes.UPDATE_PRODUCT_ID_REPO;
  allProductIdsRepo: IOrder[];
}

export interface ISetTablePropsForDeleteAccountSerial {
  type: ProductActionTypes.SET_TABLE_PROPS_FOR_DELETE_ACCOUNTS_SERIAL;
  deleteAccountSerialTableState: State;
}

export type ProductActions = IGetProductsAction | ISetExpandedStatus | IGetProductSerialsToDisplayAction | IActivateProductsAction | IAssignProductsAction | ISetActivateAssignErrorAction | ISetSelectedProductAction | IGetSubpartnersWithProductsAssignedAction | IGetSubpartnersWithProductsAssignedCancelTokenAction | IUnassignProductsAction | ISetAccountProducts | IGetParentMspProductsAction | ISetParentProductsCancelTokenAction | IHasSubpartnersWithProducts | IUpdateSkuServicesRepo | ISetTablePropsForBBS | ISetTablePropsForESS | ISetTablePropsForCS | ISetTablePropsForSE | IDeactivateProductsAction | IRetryActivationProductsAction | IChangeSerial | ISetLoadingProductsForAccountId | ISetLoadingProductsCanceled | ISetLoadingParentProductsCanceled | IGetProductsForSubpartnersAction | IClearSubpartnersWithProductsAction | IGetCurrency | ISetCancelLoadCurrencyToken | IExportCsvReport | IGetOrders | ISetAccountOrders | ISetTablePropsForSerial | IGetDeleteAccountSerials | ISetCancelGetAllSerialsToken | IUpdateProductIdRepo | ISetTablePropsForDeleteAccountSerial | IFetchChangeSerialOptions;

export const getProductsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetProductsAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const curentlyLoadingProductsForAccountId = getState().productState.loadingProductsForAccountId;
    if (curentlyLoadingProductsForAccountId === account.id) {
      return true;
    }
    dispatch({
      productsToDisplay: [],
      type: ProductActionTypes.GET_PRODUCTS,
      loadingProducts: true,
    });
    const newCancelTokenSource = cancelGeneralActionTokenAndCreateNew(getState, dispatch);
    try {
      const { mspAccounts } = getState().accountState;

      dispatch({
        loadingProductsForAccountId: account.id,
        type: ProductActionTypes.SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID,
      });

      dispatch({
        loadingOrdersForAccountId: account.id,
        type: ProductActionTypes.GET_ORDERS,
      });
      const ordersResult = await mspService.loadOrders(apiUrl, account.id, newCancelTokenSource.token, false);
      const filteredOrders = filterOrdersResult(ordersResult);
      updateProductIdsRepo(getState, ordersResult, dispatch);
      const { accountsOrders } = getState().productState;
      if (account.type !== MspType.Customer) {
        const nextStateAccountsOrders = overwriteOrdersForAccount(filteredOrders, account.id, accountsOrders);
        dispatch({
          type: ProductActionTypes.SET_ACCOUNT_ORDERS,
          accountsOrders: nextStateAccountsOrders,
        });
      }
      dispatch({
        loadingOrdersForAccountId: 0,
        type: ProductActionTypes.GET_ORDERS,
      });

      const productFamilies = await mspService.loadSerialsAndComputeProducts(apiUrl, filteredOrders, account.id, account.type, mspAccounts, newCancelTokenSource.token);
      updateServicesRepo(productFamilies, dispatch);

      dispatch({
        type: ProductActionTypes.GET_PRODUCTS,
        productsToDisplay: productFamilies,
        loadingProducts: false,
      });
      dispatch({
        loadingProductsForAccountId: 0,
        type: ProductActionTypes.SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID,
      });
      dispatch({
        loadingProductsCanceledForAccountId: 0,
        type: ProductActionTypes.SET_LOADING_PRODUCTS_CANCELED,
      });

      const { accountsProducts } = getState().productState;

      const nextStateAccountsProducts = overwriteSavedProductFamilyForAccount(productFamilies, account.id, accountsProducts);
      dispatch({
        type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
        accountsProducts: nextStateAccountsProducts,
      });
      return productFamilies;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ProductActionTypes.GET_PRODUCTS,
            productsToDisplay: getState().productState.productsToDisplay,
            loadingProducts: false,
          });
          dispatch({
            loadingProductsForAccountId: 0,
            type: ProductActionTypes.SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID,
          });
          dispatch({
            loadingOrdersForAccountId: 0,
            type: ProductActionTypes.GET_ORDERS,
          });
        },
        () => {
          dispatch({
            loadingProductsCanceledForAccountId: account.id,
            type: ProductActionTypes.SET_LOADING_PRODUCTS_CANCELED,
          });
          dispatch({
            loadingProductsForAccountId: 0,
            type: ProductActionTypes.SET_LOADING_PRODUCTS_FOR_ACCOUNT_ID,
          });
          dispatch({
            loadingOrdersForAccountId: 0,
            type: ProductActionTypes.GET_ORDERS,
          });
        },
      );
      return false;
    }
  };
};

export const getParentMspProductsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetParentMspProductsAction>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl, mspAccountLoggedIn } = getState().generalState;
    const { accountsProducts } = getState().productState;
    let shouldGetMspProductsForSubpartnerAccount = false;
    if (account.closestParentId !== mspAccountLoggedIn.id) {
      const index = accountsProducts.findIndex((x: IAccountProducts) => x.accountId.toString() === mspAccountLoggedIn.id.toString());
      if (index < 0) {
        shouldGetMspProductsForSubpartnerAccount = true;
      }
    }
    dispatch({
      parentProductsFamilies: { accountId: account.closestParentId, productFamilies: [] },
      type: ProductActionTypes.GET_PARENT_MSP_PRODUCTS,
      loadingParentMspProducts: true,
    });
    const newCancelTokenSource = cancelLoadParentProductsActionTokenAndCreateNew(getState, dispatch);

    const accountIndex = accountsProducts.findIndex((x: IAccountProducts) => x.accountId === account.closestParentId);
    if (accountIndex > -1) {
      dispatch({
        parentProductsFamilies: { accountId: account.closestParentId, productFamilies: accountsProducts[accountIndex].productFamilies },
        type: ProductActionTypes.GET_PARENT_MSP_PRODUCTS,
        loadingParentMspProducts: false,
      });
      return true;
    } else {
      try {
        const { mspAccounts } = getState().accountState;
        const parentProductFamilies = await mspService.loadProductsIdsAndName(apiUrl, account.closestParentId, mspAccounts, newCancelTokenSource.token);
        updateServicesRepo(parentProductFamilies, dispatch);
        let nextStateAccountsProducts = overwriteSavedProductFamilyForAccount(parentProductFamilies, account.closestParentId, accountsProducts);

        if (shouldGetMspProductsForSubpartnerAccount) {
          const parentProductFamilies2 = await mspService.loadProductsIdsAndName(apiUrl, mspAccountLoggedIn.id, mspAccounts, newCancelTokenSource.token);
          updateServicesRepo(parentProductFamilies2, dispatch);
          nextStateAccountsProducts = overwriteSavedProductFamilyForAccount(parentProductFamilies2, mspAccountLoggedIn.id, nextStateAccountsProducts);
        }
        dispatch({
          type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
          accountsProducts: nextStateAccountsProducts,
        });

        dispatch({
          type: ProductActionTypes.GET_PARENT_MSP_PRODUCTS,
          parentProductsFamilies: { accountId: account.closestParentId, productFamilies: parentProductFamilies },
          loadingParentMspProducts: false,
        });
        dispatch({
          loadingParentProductsCanceled: false,
          type: ProductActionTypes.SET_LOADING_PARENT_PRODUCTS_CANCELED,
        });
        return true;
      } catch (err) {
        handleError(
          err,
          dispatch,
          () => {
            dispatch({
              type: ProductActionTypes.GET_PARENT_MSP_PRODUCTS,
              parentProductsFamilies: { accountId: account.closestParentId, productFamilies: [] },
              loadingParentMspProducts: false,
            });
          },
          () => {
            dispatch({
              loadingParentProductsCanceled: true,
              type: ProductActionTypes.SET_LOADING_PARENT_PRODUCTS_CANCELED,
            });
          },
        );
        return false;
      }
    }
  };
};

export const getProductSerialsToDisplayAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetProductSerialsToDisplayAction>> = (product: any, refreshSerials?: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { accountsProducts } = getState().productState;
    const { prevSelectedAccount } = getState().accountState;
    let selectedProduct = product;
    if (refreshSerials) {
      selectedProduct = getAccountProductFromAccountProducts(product, (prevSelectedAccount as IAccount).id, accountsProducts);
    }
    dispatch({
      serialsToDisplay: [],
      type: ProductActionTypes.GET_SERIALS_TO_DISPLAY,
    });

    const { mspAccounts } = getState().accountState;
    const { accountsNames } = getState().accountState;
    const serials = getSerialsWithAccountId(selectedProduct.serials, mspAccounts, accountsNames);
    dispatch({
      type: ProductActionTypes.GET_SERIALS_TO_DISPLAY,
      serialsToDisplay: serials,
    });
    return serials;
  };
};

export const setProductExpandedStatusAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetExpandedStatus>> = (productFamily: ProductFamily, isExpanded: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { expandedStatus } = getState().productState;
    const nextStateExpandedStatus = produce(expandedStatus, draft => {
      draft[productFamily] = isExpanded;
    });
    dispatch({
      type: ProductActionTypes.SET_EXPANDED_STATUS,
      expandedStatus: nextStateExpandedStatus,
    });
    return true;
  };
};

export const retryActivationProductAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IRetryActivationProductsAction>> = (mspAccountLoggedIn: IAccount, product: IProduct, serialToDisplay: ISerial, selectedAccount: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const result = await mspService.retryActivationProduct(apiUrl, mspAccountLoggedIn, product, serialToDisplay.bccProductId);
      const updatedStatus = getStatusFromResponse(result.status);
      const { accountsProducts } = getState().productState;
      let nextStateAccountProducts: IAccountProducts[];
      if (productIsBBS(product)) {
        nextStateAccountProducts = updateBBSProductForParentAccountForAfterActivateDeactivate(product, selectedAccount, accountsProducts, true, updatedStatus, true);
      } else {
        nextStateAccountProducts = updateSerialStatusForParentProduct(product, selectedAccount, accountsProducts, serialToDisplay, updatedStatus);
      }
      if (mspAccountLoggedIn.type === MspType.Partner) {
        const { mspAccounts } = getState().accountState;
        const selectedAccountParent = mspAccounts.find(e => e.id === selectedAccount.closestParentId);
        if (selectedAccountParent?.type === MspType.Subpartner) {
          if (productIsBBS(product)) {
            nextStateAccountProducts = updateBBSProductForParentAccountForAfterActivateDeactivate(product, selectedAccountParent, nextStateAccountProducts, true, updatedStatus, false);
          } else {
            nextStateAccountProducts = updateSerialStatusForParentProduct(product, selectedAccountParent, nextStateAccountProducts, serialToDisplay, updatedStatus);
          }
        }
      }

      dispatch({
        type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
        accountsProducts: nextStateAccountProducts,
      });

      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.RetryActivation,
      );
      return false;
    }
  };
};

export const activateProductsForAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IActivateProductsAction>> = (account: IAccount, product: IProduct) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const result = await mspService.activateProduct(apiUrl, account, product);
      const updatedStatus = getStatusFromResponse(result.status);
      const { accountsProducts } = getState().productState;
      if (productIsBBS(product)) {
        const nextStateAccountProducts = updateBBSProductForParentAccountForAfterActivateDeactivate(product, account, accountsProducts, true, updatedStatus, true);
        dispatch({
          type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
          accountsProducts: nextStateAccountProducts,
        });
      }
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.ActivateProduct,
      );
      return false;
    }
  };
};

export const deactivateProductsForAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeactivateProductsAction>> = (mspAccountLoggedIn: IAccount, product: IProduct, selectedAccount: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.deactivateProduct(apiUrl, mspAccountLoggedIn, product);
      const { accountsProducts } = getState().productState;
      const { mspAccounts } = getState().accountState;
      const nextStateAccountProducts = updateBBSProductsInStateAfterDeactivateSerial(accountsProducts, product, selectedAccount, mspAccounts, mspAccountLoggedIn);
      dispatch({
        type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
        accountsProducts: nextStateAccountProducts,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.DeactivateSerial,
      );
      return false;
    }
  };
};

export const cancelSerialAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IDeactivateProductsAction>> = (mspAccountLoggedIn: IAccount, product: IProduct, selectedAccount: IAccount, bccProductId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.cancelSerial(apiUrl, selectedAccount, product, bccProductId.toString());

      const { accountsProducts } = getState().productState;
      const { mspAccounts } = getState().accountState;
      let nextStateAccountProducts = accountsProducts;

      nextStateAccountProducts = updateESSCSProductForParentAccountForAfterCancelSerial(product, selectedAccount, accountsProducts, bccProductId);
      const selectedAccountParent = mspAccounts.find(e => e.id === selectedAccount.closestParentId);
      if (selectedAccountParent?.type === MspType.Subpartner && mspAccountLoggedIn.type === MspType.Partner) {
        nextStateAccountProducts = updateESSCSProductForParentAccountForAfterCancelSerial(product, selectedAccountParent, nextStateAccountProducts, bccProductId);
      }

      dispatch({
        type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
        accountsProducts: nextStateAccountProducts,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.RemoveSerial,
      );
      return false;
    }
  };
};

export const assignProductsForAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IAssignProductsAction>> = (account: IAccount, product: IProduct) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.assignProduct(apiUrl, account, product);

      const { accountsProducts } = getState().productState;
      const productWithSku: IProduct = await getServicesSkusForProduct(getState, product, dispatch);

      let nextStateAccountProducts: IAccountProducts[] = [];
      if (productIsBBS(product)) {
        const nextStateProduct = produce(productWithSku, (draft: IProduct) => {
          draft.subPartnerId = account.id;
          draft.serials[0].status = SerialStatus.AVAILABLE;
        });
        nextStateAccountProducts = addProductForAccount(nextStateProduct, account, accountsProducts);
        nextStateAccountProducts = updateBBSProductForParentAccountForAfterAssignUnassign(nextStateProduct, account, nextStateAccountProducts, true);
      } else {
        nextStateAccountProducts = addProductForAccount(product, account, accountsProducts);
      }

      dispatch({
        type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
        accountsProducts: nextStateAccountProducts,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ProductActionTypes.ASSIGN_PRODUCTS,
          });
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.AssignProduct,
      );
      return false;
    }
  };
};

export const unassignProductsForAccountAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IUnassignProductsAction>> = (account: IAccount, product: IProduct) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.unassignProduct(apiUrl, account, product);

      const { accountsProducts } = getState().productState;
      let nextStateAccountProducts = removeProductForAccount(product, account, accountsProducts);
      if (productIsBBS(product)) {
        const updatedNextStateAccountProducts = updateBBSProductForParentAccountForAfterAssignUnassign(product, account, nextStateAccountProducts, false);
        dispatch({
          type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
          accountsProducts: updatedNextStateAccountProducts,
        });
      } else {
        dispatch({
          type: ProductActionTypes.SET_ACCOUNT_PRODUCTS,
          accountsProducts: nextStateAccountProducts,
        });
      }
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ProductActionTypes.UNASSIGN_PRODUCTS,
          });
        },
        () => {},
        true,
        ActionTypes.UnassignProduct,
      );
      return false;
    }
  };
};

export const setSelectedProductAction: ActionCreator<ThunkAction<any, IAppState, null, ISetSelectedProductAction>> = (selectedProduct: IProduct) => (dispatch: Dispatch) => dispatch({ type: ProductActionTypes.SET_SELECTED_PRODUCT, selectedProduct });

export const getProductSerialForUser: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetProductSerialsToDisplayAction>> = (account: IAccount, product?: IProduct) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { serialsToDisplay } = getState().productState;
    if (serialsToDisplay.length > 0) {
      const userSerial = serialsToDisplay.filter(s => {
        if (productIsBBS(product)) {
          return s.accountId.toString() === "0";
        } else {
          return s.accountId.toString() === account.id.toString();
        }
      });
      dispatch({
        type: ProductActionTypes.GET_SERIALS_TO_DISPLAY,
        serialsToDisplay: userSerial,
      });
    }
  };
};

export const getSubpartnersWithAssignedProductsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetSubpartnersWithProductsAssignedAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const subpartners = getState().productState.subpartnersWithProducts;
    try {
      dispatch({
        type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS,
        subpartnersWithProducts: subpartners,
        loadingSubpartnersWithProducts: true,
      });

      const newCancelTokenSource = cancelLoadSupartnersWithAssignedProductsActionTokenAndCreateNew(getState, dispatch);

      const { mspAccounts } = getState().accountState;
      let updatedSubpartnersList: ISubpartnersWithProducts[] = [];
      if (subpartners.length > 0) {
        const supartnersToBeDisplayed = process(subpartners, { skip: 0, take: 10, sort: [{ field: "accountName", dir: "asc" }] }).data;
        const usersWithExtraInfo = await loadProductsForSubpartners(supartnersToBeDisplayed, apiUrl, mspAccounts, newCancelTokenSource);
        updatedSubpartnersList = updateUsersListWithExtraInfo(subpartners, usersWithExtraInfo);
      }

      dispatch({
        type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS,
        subpartnersWithProducts: updatedSubpartnersList,
        loadingSubpartnersWithProducts: false,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS,
          subpartnersWithProducts: subpartners,
          loadingSubpartnersWithProducts: false,
        });
      });
      return false;
    }
  };
};

export const getProductsForSubpartnersAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetSubpartnersWithProductsAssignedAction>> = (subpartners: ISubpartnersWithProducts[]) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    const subpartners1 = getState().productState.subpartnersWithProducts;
    try {
      const newCancelTokenSource = cancelLoadSupartnersWithAssignedProductsActionTokenAndCreateNew(getState, dispatch);

      const { mspAccounts } = getState().accountState;

      let usersWithoutExtraInfo: ISubpartnersWithProducts[] = [];
      subpartners.forEach((u: ISubpartnersWithProducts) => {
        if (u.totalProducts === 0) {
          usersWithoutExtraInfo.push(u);
        }
      });

      if (usersWithoutExtraInfo.length > 0) {
        dispatch({
          type: ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS,
          loadingProductsForSubpartners: true,
        });
        let updatedSubpartnersList: ISubpartnersWithProducts[] = [];
        const usersWithExtraInfo = await loadProductsForSubpartners(usersWithoutExtraInfo, apiUrl, mspAccounts, newCancelTokenSource);
        updatedSubpartnersList = updateUsersListWithExtraInfo(subpartners1, usersWithExtraInfo);
        dispatch({
          type: ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS,
          loadingProductsForSubpartners: false,
        });
        dispatch({
          type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS,
          subpartnersWithProducts: updatedSubpartnersList,
          loadingSubpartnersWithProducts: false,
        });
      } else {
        dispatch({
          type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS,
          subpartnersWithProducts: subpartners1,
          loadingSubpartnersWithProducts: false,
        });
        dispatch({
          type: ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS,
          loadingProductsForSubpartners: false,
        });
      }
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: ProductActionTypes.GET_SUBPARTNERS_WITH_ASSIGNED_PRODUCTS,
          subpartnersWithProducts: [],
          loadingSubpartnersWithProducts: false,
        });
        dispatch({
          type: ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS,
          loadingProductsForSubpartners: false,
        });
      });
      return false;
    }
  };
};

export const setLoadingProductsForSubpartner: ActionCreator<ThunkAction<any, IAppState, null, IGetProductsForSubpartnersAction>> = (loadingProductsForSubpartners: boolean) => (dispatch: Dispatch) => dispatch({ type: ProductActionTypes.GET_PRODUCTS_FOR_SUBPARTNERS, loadingProductsForSubpartners });

export const clearProductsFromSubpartnersWithProducts: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IClearSubpartnersWithProductsAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { subpartnersWithProducts } = getState().productState;
    const emptyData = subpartnersWithProducts.map((subpartner: ISubpartnersWithProducts) => ({ ...subpartner, totalProducts: 0 }));
    dispatch({
      type: ProductActionTypes.CLEAR_PRODUCTS_FROM_SUBPARTNERS_WITH_PRODUCTS,
      subpartnersWithProducts: emptyData,
    });
    return true;
  };
};

export const setTablePropsForBBS: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForBBS>> = (bbsTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.PRODUCTS_UI_BBS, JSON.stringify({ ...bbsTableState, skip: 0 }));
    dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_BBS, bbsTableState });
  };
};

export const setTablePropsForESS: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForESS>> = (essTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.PRODUCTS_UI_ESS, JSON.stringify({ ...essTableState, skip: 0 }));
    dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_ESS, essTableState });
  };
};
export const setTablePropsForSE: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForSE>> = (seTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.PRODUCTS_UI_SE, JSON.stringify({ ...seTableState, skip: 0 }));
    dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_SE, seTableState });
  };
};
export const setTablePropsForCS: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForCS>> = (csTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.PRODUCTS_UI_CS, JSON.stringify({ ...csTableState, skip: 0 }));
    dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_CS, csTableState });
  };
};

export const getHasSubpartnersWithProducts: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetSubpartnersWithProductsAssignedAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const { productsToDisplay } = getState().productState;
      const availableSubpartners = getState().accountState.mspAccounts.filter(x => x.type === MspType.Subpartner);
      if (productsToDisplay.length === 0 || availableSubpartners.length === 0) {
        dispatch({
          type: ProductActionTypes.GET_HAS_SUBPARTNERS_WITH_PRODUCTS,
          hasSubpartnersWithProducts: false,
          loadingHasSubpartnersWithProducts: false,
          subpartnersWithProducts: [],
        });
        return true;
      }

      dispatch({
        type: ProductActionTypes.GET_HAS_SUBPARTNERS_WITH_PRODUCTS,
        hasSubpartnersWithProducts: false,
        loadingHasSubpartnersWithProducts: true,
        subpartnersWithProducts: [],
      });

      let hasSubpartnersWithProducts = false;
      let subpartnersWithAssignedProducts: ISubpartnersWithProducts[] = [];
      const subpartners = await mspService.loadSubpartnersWithOrders(apiUrl, undefined);
      if (subpartners.length > 0) {
        hasSubpartnersWithProducts = true;
        subpartnersWithAssignedProducts = [...subpartners.map((subpartner: IAccount) => ({ accountId: subpartner.id, accountName: subpartner.name, totalProducts: 0, totalErrors: 0, hasPendingSerials: false, hasSerials: false, products: [] }))];
      }

      dispatch({
        type: ProductActionTypes.GET_HAS_SUBPARTNERS_WITH_PRODUCTS,
        hasSubpartnersWithProducts: hasSubpartnersWithProducts,
        loadingHasSubpartnersWithProducts: false,
        subpartnersWithProducts: subpartnersWithAssignedProducts,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: ProductActionTypes.GET_HAS_SUBPARTNERS_WITH_PRODUCTS,
          hasSubpartnersWithProducts: false,
          loadingHasSubpartnersWithProducts: false,
          subpartnersWithProducts: [],
        });
      });
      return false;
    }
  };
};

export const changeSerialAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IChangeSerial>> = (account: IAccount, orderlineItem: IProduct, serial: ISerial, lineItem: IProduct) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.changeSerial(apiUrl, account.closestParentId, orderlineItem.id, serial.serial, lineItem.id);
      dispatch({
        type: ProductActionTypes.CHANGE_SERIAL,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.ChangeService,
      );
      return false;
    }
  };
};

export const queueExportUsersRequestAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IExportBillingUsers>> = (accountId: number, includedAccounts: number[], excludedAccounts: number[], allSelected: boolean) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      await mspService.queueExportUsersRequest(apiUrl, accountId, includedAccounts, excludedAccounts, allSelected);
      dispatch({
        type: ProductActionTypes.EXPORT_BILLING_USERS,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
        true,
        ActionTypes.ExportBillingUsers,
      );
      return false;
    }
  };
};

export const fetchChangeSerialOptionsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IFetchChangeSerialOptions>> = (account: IAccount, orderlineItem: IProduct, serial: ISerial) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    try {
      const { apiUrl } = getState().generalState;
      const { mspAccounts } = getState().accountState;
      return await mspService.fetchChangeSerialOptions(apiUrl, account.closestParentId, orderlineItem.id, serial.serial, mspAccounts, undefined);
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          /* no action needed*/
        },
        () => {
          /* no action needed*/
        },
      );
      return [];
    }
  };
};

export const getSerialsAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetProductSerialsToDisplayAction>> = (accountId: number, orderlineItem: IProduct) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const { mspAccounts } = getState().accountState;
      const { accountsNames } = getState().accountState;
      const result = await mspService.loadProductSerials(apiUrl, accountId, orderlineItem, undefined);
      const serials = getSerialsWithAccountId(result.serials, mspAccounts, accountsNames);
      dispatch({
        type: ProductActionTypes.GET_SERIALS_TO_DISPLAY,
        serialsToDisplay: serials,
      });
      return true;
    } catch (err) {
      handleError(err, dispatch, () => {
        dispatch({
          type: ProductActionTypes.GET_SERIALS_TO_DISPLAY,
          serialsToDisplay: [],
        });
      });
      return false;
    }
  };
};

export const cancelGetSubpartnersWithProductsAssignedAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetSubpartnersWithProductsAssignedCancelTokenAction>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    cancelLoadSupartnersWithAssignedProductsActionToken(getState, dispatch);
  };
};

async function loadProductsForSubpartners(subparteners: ISubpartnersWithProducts[], apiUrl: string, mspAccounts: IAccount[], newCancelTokenSource: CancelTokenSource) {
  return loadProductsForSubpartnersRecursive(subparteners, apiUrl, mspAccounts, newCancelTokenSource);
}

async function loadProductsForSubpartnersRecursive(subparteners: ISubpartnersWithProducts[], apiUrl: string, mspAccounts: IAccount[], newCancelTokenSource: CancelTokenSource): Promise<any> {
  let tokenExpired = false;
  const orderPromises = subparteners.map(async (subpartner: ISubpartnersWithProducts) => {
    const ordersResult = await mspService.loadOrders(apiUrl, subpartner.accountId, newCancelTokenSource.token, true);
    const filteredOrders = filterOrdersResult(ordersResult);
    const products = await mspService.loadSerialsAndComputeProducts(apiUrl, filteredOrders, subpartner.accountId, subpartner.accountName, mspAccounts, newCancelTokenSource.token);
    //TODO save orders state for filtering?
    return getSubpartnerProductsSummary(products, subpartner);
  });
  const results = await Promise.all(
    orderPromises.map(async url => {
      try {
        return await url;
      } catch (error: any) {
        if (isTokenExpiredError(error)) {
          tokenExpired = true;
        } else {
          throw error;
        }
      }
    }),
  );

  if (tokenExpired) {
    return TokenStorage.refreshEchoV3AccessToken().then(() => {
      return loadProductsForSubpartnersRecursive(subparteners, apiUrl, mspAccounts, newCancelTokenSource);
    });
  } else {
    // eslint-disable-next-line array-callback-return
    return (results as unknown as ISubpartnersWithProducts[]).filter(x => x.totalProducts > 0);
  }
}

async function getServicesSkusForProduct(getState: () => IAppState, product: IProduct, dispatch: Dispatch): Promise<IProduct> {
  let productWithSkus: IProduct;
  if (product.type === ProductFamily.ESSENTIALS_SERIVICES || product.type === ProductFamily.SECUREEDGE_SERVICES) {
    let services: IService[] = [];
    if (product.sku) {
      const { skuServicesRepo } = getState().productState;
      const serviceIndex = skuServicesRepo.findIndex((serv: IBundleSku) => serv.sku === product.sku);
      if (serviceIndex > -1) {
        services = skuServicesRepo[serviceIndex].services;
      } else {
        await getSku(getState, product.sku, undefined, services, dispatch);
      }
    } else {
      await getSku(getState, product.sku, undefined, services, dispatch);
    }
    productWithSkus = { ...product, services };
  } else {
    productWithSkus = { ...product, services: [] };
  }

  return productWithSkus;
}

async function getSku(getState: () => IAppState, bundleSku: string, cancelToken: CancelToken | undefined, services: IService[], dispatch: Dispatch) {
  const { apiUrl } = getState().generalState;
  const servicesSkusInfo: IService[] = await mspService.loadBundleServicesSkus(apiUrl, bundleSku, cancelToken);
  servicesSkusInfo.forEach((ssi: IService) => {
    services.push(ssi);
  });
  dispatchAddSkuServices(getState, dispatch, { sku: bundleSku, services });
}

function dispatchAddSkuServices(getState: () => IAppState, dispatch: Dispatch, newInfo: IBundleSku) {
  const { skuServicesRepo } = getState().productState;
  const nextStateServiceSku = produce(skuServicesRepo, draft => {
    draft.push(newInfo);
  });
  dispatch({
    type: ProductActionTypes.UPDATE_SKU_SERVICES_REPO,
    skuServicesRepo: nextStateServiceSku,
  });
}

export function updateUsersListWithExtraInfo(usersToDisplay: ISubpartnersWithProducts[], usersWithExtraInfo: ISubpartnersWithProducts[]): ISubpartnersWithProducts[] {
  let results: ISubpartnersWithProducts[] = [];
  usersToDisplay.forEach((x: any) => {
    const extraDetailsUserIndex = usersWithExtraInfo.findIndex((y: any) => y.accountId === x.accountId);
    if (extraDetailsUserIndex > -1) {
      results.push(usersWithExtraInfo[extraDetailsUserIndex]);
    } else {
      results.push(x);
    }
  });
  return results;
}

function updateServicesRepo(productfamily: IProductFamily[], dispatch: Dispatch) {
  let essentialsFamily = productfamily.find(element => element.productType === ProductFamily.ESSENTIALS_SERIVICES || element.productType === ProductFamily.SECUREEDGE_SERVICES);
  const essentialsBundles = essentialsFamily?.products.map(({ sku, services }) => ({ sku, services }));
  dispatch({
    type: ProductActionTypes.UPDATE_SKU_SERVICES_REPO,
    skuServicesRepo: essentialsBundles ?? [],
  });
}

export function updateProductIdsRepo(getState: () => IAppState, orders: IOrder[], dispatch: Dispatch) {
  const { allProductIdsRepo } = getState().productState;
  let newStateOrders = [...allProductIdsRepo];
  orders.forEach((newOrder: IOrder) => {
    const orderIndex = allProductIdsRepo?.findIndex(x => x.lineItemId === newOrder.lineItemId);
    if (orderIndex < 0) {
      newStateOrders.push(newOrder);
    }
  });
  dispatch({
    type: ProductActionTypes.UPDATE_PRODUCT_ID_REPO,
    allProductIdsRepo: newStateOrders,
  });
}

export const getCurrencyAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetCurrency>> = (account: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      dispatch({
        type: ProductActionTypes.GET_CURRENCY,
        currency: "",
        loadingCurrency: true,
      });
      const newCancelTokenSource = cancelLoadCurrencyActionTokenAndCreateNew(getState, dispatch);
      const result = await mspService.loadCurrency(apiUrl, account.id, newCancelTokenSource.token);
      dispatch({
        type: ProductActionTypes.GET_CURRENCY,
        currency: result.currencyCode,
        loadingCurrency: false,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            type: ProductActionTypes.GET_CURRENCY,
            currency: "",
            loadingCurrency: false,
          });
        },
        () => {
          dispatch({
            type: ProductActionTypes.GET_CURRENCY,
            currency: "",
            loadingCurrency: false,
          });
        },
      );
      return false;
    }
  };
};

export const resetProductsToDisplayAction: ActionCreator<ThunkAction<any, IAppState, null, IGetProductsAction>> = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      productsToDisplay: [],
      type: ProductActionTypes.GET_PRODUCTS,
      loadingProducts: false,
      loadingProductsForAccountId: 0,
    });
  };
};

export const exportCsvReportAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IExportCsvReport>> = (mspAccountLoggedIn: IAccount, selectedAccount: IAccount) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    const { apiUrl } = getState().generalState;
    try {
      const payloadIds = exportCsvPayloadAccountsIds(mspAccountLoggedIn, selectedAccount);
      await mspService.exportCsv(apiUrl, payloadIds.toString());
      dispatch({
        type: ProductActionTypes.EXPORT_CSV_REPORT,
      });
      return true;
    } catch (err) {
      handleError(
        err,
        dispatch,
        () => {
          //nothing
        },
        () => {
          //nothing
        },
        true,
        ActionTypes.ExportCsvReport,
      );
      return false;
    }
  };
};

export const getOrdersForAccountsFilteringAction: ActionCreator<ThunkAction<Promise<any>, IAppState, null, ISetAccountOrders>> = (accountId: number) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    return await getOrdersForAccountsFiltering(getState, dispatch, accountId);
  };
};

export async function getOrdersForAccountsFiltering(getState: () => IAppState, dispatch: Dispatch<any>, accountId: number) {
  const { apiUrl, mspAccountLoggedIn } = getState().generalState;
  try {
    dispatch({
      loadingOrdersForAccountId: accountId,
      type: ProductActionTypes.GET_ORDERS,
    });
    const { accountsOrders } = getState().productState;
    let updatedOrders = await ensureOrdersAreLoadedForAccountid(accountId, accountsOrders, apiUrl);
    if (accountId !== mspAccountLoggedIn.id && mspAccountLoggedIn.id > 0) {
      updatedOrders = await ensureOrdersAreLoadedForAccountid(mspAccountLoggedIn.id, updatedOrders, apiUrl);
    }
    dispatch({
      type: ProductActionTypes.SET_ACCOUNT_ORDERS,
      accountsOrders: updatedOrders,
    });
    dispatch({
      loadingOrdersForAccountId: 0,
      type: ProductActionTypes.GET_ORDERS,
    });
    return updatedOrders.filter((x: IAccountOrders) => x.accountId === accountId)[0].orders;
  } catch (err) {
    handleError(
      err,
      dispatch,
      () => {
        dispatch({
          loadingOrdersForAccountId: 0,
          type: ProductActionTypes.GET_ORDERS,
        });
      },
      () => {
        //nothing
      },
    );
    return false;
  }
}

export async function ensureOrdersAreLoadedForAccountid(accountId: number, loadedOrders: IAccountOrders[], apiUrl: string) {
  let result: IAccountOrders[] = [];
  const partnerOrdersIndex = loadedOrders.findIndex(x => x.accountId === accountId);
  if (partnerOrdersIndex < 0) {
    const ordersResult = await mspService.loadOrders(apiUrl, accountId, undefined, false);
    const partenerFilteredOrders = filterOrdersResult(ordersResult);
    result = overwriteOrdersForAccount(partenerFilteredOrders, accountId, loadedOrders);
  } else {
    result = loadedOrders;
  }
  return result;
}

export const getLoadedAccountOrdersAction: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForSerial>> = () => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    return getState().productState.accountsOrders;
  };
};

export const setTablePropsForSerial: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForSerial>> = (serialTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.SERIALS_UI, JSON.stringify({ ...serialTableState, skip: 0 }));
    dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_SERIAL, serialTableState });
  };
};

export const getAllSerialsForAccount: ActionCreator<ThunkAction<Promise<any>, IAppState, null, IGetDeleteAccountSerials>> = (accountId: number | undefined) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    if (accountId === undefined) {
      dispatch({
        type: ProductActionTypes.GET_DELETE_ACCOUNT_SERIALS,
        loadingDeleteAccountSerials: false,
        deleteAccountSerialsToDisplay: undefined,
        allDeleteAccountSerials: undefined,
      });
      return;
    }
    const { apiUrl } = getState().generalState;
    try {
      dispatch({
        deleteAccountSerialsToDisplay: [],
        allDeleteAccountSerials: [],
        type: ProductActionTypes.GET_DELETE_ACCOUNT_SERIALS,
        loadingDeleteAccountSerials: true,
      });
      const newCancelTokenSource = cancelLoadAllSerialsActionTokenAndCreateNew(getState, dispatch);
      const allSerials = await mspService.getAllSerials(apiUrl, accountId, newCancelTokenSource.token);
      if (allSerials?.serials) {
        const result = await computeSerialsForAccountToDelete(allSerials.serials, accountId, getState, dispatch);
        const resultsToDisplay = result?.filter(x => x.status !== SerialStatus.PROVISION_FAILED && x.status !== SerialStatus.AVAILABLE);
        let resultsToDelete = result?.filter(x => x.status !== SerialStatus.PENDING && x.status !== SerialStatus.SSG_PENDING);
        resultsToDelete = resultsToDelete?.filter(x => x.productFamily === ProductFamily.BACKUP_APPLIANCES || x.productFamily === ProductFamily.ESSENTIALS_SERIVICES || x.productFamily === ProductFamily.CONTENT_SHIELD || x.productFamily === ProductFamily.SECUREEDGE_SERVICES);

        dispatch({
          deleteAccountSerialsToDisplay: resultsToDisplay,
          allDeleteAccountSerials: resultsToDelete,
          type: ProductActionTypes.GET_DELETE_ACCOUNT_SERIALS,
          loadingDeleteAccountSerials: false,
        });
        return true;
      }
      return false;
    } catch (err) {
      let result = false;
      handleError(
        err,
        dispatch,
        () => {
          dispatch({
            deleteAccountSerialsToDisplay: [],
            allDeleteAccountSerials: [],
            type: ProductActionTypes.GET_DELETE_ACCOUNT_SERIALS,
            loadingDeleteAccountSerials: false,
          });
        },
        () => {
          result = true;
        },
        false,
      );
      return result;
    }
  };
};

export const setTablePropsForDeleteAccountSerial: ActionCreator<ThunkAction<any, IAppState, null, ISetTablePropsForDeleteAccountSerial>> = (deleteAccountSerialTableState: State) => {
  return async (dispatch: Dispatch, getState: () => IAppState) => {
    localStorageService.setItem(getState().generalState.loggedUser.id.toString(), LocalStoragePreferences.DELETE_ACCOUNTS_SERIALS_UI, JSON.stringify({ ...deleteAccountSerialTableState, skip: 0 }));
    dispatch({ type: ProductActionTypes.SET_TABLE_PROPS_FOR_DELETE_ACCOUNTS_SERIAL, deleteAccountSerialTableState });
  };
};
